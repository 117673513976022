<template>
  <div class="secondSceneryCateList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>二级景区分类列表{{ id }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>分类名称</span>
      <el-input v-model="list.cateName" placeholder="请输入分类名称"></el-input>
      <el-button type="primary" @click="getSCCateList()">查找</el-button>
      <el-button type="primary" @click="addClassBDialogVisible = true"
        >添加</el-button
      >
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table :data="tableData" border>
      <el-table-column align="center" prop="dept" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" prop="id" label="分类ID">
      </el-table-column>
      <el-table-column align="center" prop="cateName" label="分类名称">
      </el-table-column>
      <el-table-column align="center" prop="fatherName" label="上级分类名称">
      </el-table-column>
      <el-table-column align="center" prop="addTime" label="最后修改时间">
      </el-table-column>
      <el-table-column align="center" prop="statusStr" label="状态">
      </el-table-column>
      <el-table-column align="center" prop="" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            v-if="row.status === 2"
            type="primary"
            @click="changeStatus(row.id, 1)"
            >上架</el-button
          >
          <el-button
            size="mini"
            v-if="row.status === 1"
            type="primary"
            @click="changeStatus(row.id, 2)"
            >下架</el-button
          >
          <el-button size="mini" type="primary" @click="delCate(row)"
            >删除</el-button
          >
          <el-button size="mini" type="primary" @click="onEditCalsssb(row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      title="添加分类"
      :visible.sync="addClassBDialogVisible"
      width="30%"
      class="addDialog"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="分类名称">
          <el-input
            v-model="addClassBForm.cateName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addClassBDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddCateClassBOk">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="编辑二级分类"
      :visible.sync="editClassBDialogVisible"
      width="30%"
      class="addDialog"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="分类名称">
          <el-input v-model="editClassBForm.cateName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editClassBDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditCateClassBOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  sceneryCate,
  addSceneryCate,
  changeSort,
  delSceneryCate,
  editSceneryCate,
  changeStatus,
} from "../../api/sceneryCate";
export default {
  name: "secondSceneryCateList",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      addClassBDialogVisible: false,
      editClassBDialogVisible: false,
      tableData: [],
      addClassBForm: {
        cateName: "",
        fatherId: Number(this.id),
        cateId: 0,
      },
      editClassBForm: {},
      pagination: {},
      list: {
        currentPage: 1,
        pageSize: 5,
        fatherId: Number(this.id),
        cateName: "",
      },
    };
  },
  created() {
    this.getSCCateList();
  },
  methods: {
    changeStatus(cateId, type) {
      this.$confirm("是否操作这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            cateId: cateId,
            status: type,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getSCCateList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async getSCCateList() {
      const { data } = await sceneryCate(this.list);
      this.pagination = data.pagination;
      data.list.map((item) => {
        item.inputVisible = false;
      });
      console.log(data);
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    async onAddCateClassBOk() {
      if (this.addClassBForm.cateName === "") {
        return this.$message({
          type: "error",
          message: "请把数据填写完整!",
        });
      }
      const { data } = await addSceneryCate(this.addClassBForm);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
        this.addClassBForm.cateName = "";
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
      this.addClassBDialogVisible = false;
      this.getSCCateList();
    },
    delCate(row) {
      this.$confirm("是否删除这个分类?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delSceneryCate({
            cateId: row.id,
          });
          if (data.code == 0) {
            this.getSCCateList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onEditCalsssb(row) {
      this.editClassBForm = row;
      this.editClassBDialogVisible = true;
    },
    async onEditCateClassBOk() {
      if (this.editClassBForm.cateName === "") {
        return this.$message({
          type: "error",
          message: "请把数据填写完整!",
        });
      }
      const { data } = await editSceneryCate({
        cateName: this.editClassBForm.cateName,
        cateId: this.editClassBForm.id,
        fatherId: Number(this.id),
      });
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: "编辑成功!",
        });
      } else {
        this.$message({
          type: "error",
          message: "编辑失败!",
        });
      }
      this.editClassBDialogVisible = false;
      this.getSCCateList();
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        cateId: row.id,
        dept: Number(row.dept),
      });
      row.inputVisible = false;
      this.getSCCateList();
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getSCCateList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getSCCateList();
    },
  },
};
</script>
<style lang="less" scoped>
.secondSceneryCateList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 200px;
      margin: 0 20px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>